import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';

import { EApprovalContext, EApprovalTypes, IEApprovalContext, IMainStepProps } from '..';
import { BadgeWrapper, GreyBox, PrinciplesContainer } from '../common';
import { Row } from '../../../../Common/Row/Row';
import { SelectInput } from '../../../../Common/SelectInput/SelectInput';
import { EDropDownType } from '../../../../Common/DropDown/DropDown';
import { IValue } from '../../../../../entities/IPickers';
import { UsersPicker } from '../../../../Common/Pickers/UsersPicker';
import { IUserBrief } from '../../../../../entities/IGlobal';
import { getTypesWithStatuses } from '../../../../../actions/validationActions';
import { ITypesWithStatuses } from '../../../../../entities/IValidation';
import { Button, EButtonTypeSchema } from '../../../../Common/Buttons/NewButton';
import { Badge, EBadgeColor, EBadgeShape, EBadgeSize } from '../../../../Common/Badge/Badge';

type GetTypesWithStatuses = ReturnType<typeof getTypesWithStatuses>;

const Wrapper = styled.div`
        width: 100%;
`;

export const Step5: FC<IMainStepProps> = props => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const eApprovalContext = useContext<IEApprovalContext>(EApprovalContext);
    const [statuses, setStatuses] = useState<ITypesWithStatuses[]>(undefined);

    useEffect(() => {
        eApprovalContext.modifyData(eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.approvers?.[0]?.approver || {}, 'approver', props.context, props.stepName, props.stepNumber, 0);
    }, []);

    useEffect(() => {
        dispatch<GetTypesWithStatuses>(getTypesWithStatuses()).then(response => {
            setStatuses(response);
        });
    }, []);

    const onChangeStatus = (option: IValue, index: number) => {
        eApprovalContext.modifyData(option?.data.type, 'status', props.context, props.stepName, props.stepNumber, index);
    };

    const onChangeManager = (element: { key: string; text: string; data: IUserBrief; }[], index: number) => {
        eApprovalContext.modifyData(element?.[0]?.data, 'approver', props.context, props.stepName, props.stepNumber, index);
    };

    const onAddItem = () => {
        eApprovalContext.modifyData({}, 'approver', props.context, props.stepName, props.stepNumber, eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.approvers?.length);
    };

    const removeElement = (index: number) => {
        eApprovalContext.removeFromArrays(props.context, props.stepName, index);
    };

    return (
        <Wrapper>
            <PrinciplesContainer>
                <div dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: `eApproval.wizard.step5.approvers.free-declaration` })
                }} />
            </PrinciplesContainer>
            {eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.approvers?.map((elem, index) =>
                <GreyBox>
                    {index > 0 &&
                        <BadgeWrapper>
                            <Badge icon={faTrash} shape={EBadgeShape.CIRCLE} color={EBadgeColor.RED} size={EBadgeSize.SMALL} onClick={() => removeElement(index)} />
                        </BadgeWrapper>
                    }
                    <Row numberOfElements={2} noBorder>
                        <UsersPicker
                            value={elem.approver?.id ? [{
                                key: elem.approver?.id,
                                text: `${elem.approver?.firstName}
                                    ${elem.approver?.lastName}`,
                                data: elem.approver
                            }] : undefined}
                            onSelectElement={value => onChangeManager(value, index)}
                            required
                            label={<FormattedMessage id={`eApproval.wizard.approvers.approver`} />}
                        />
                        <SelectInput
                            value={elem.status ? [{
                                key: elem.status,
                                text: elem.status,
                                data: elem.status
                            }] : undefined}
                            required
                            onChange={(option) => onChangeStatus(option, index)}
                            inputLabel={<FormattedMessage id={'eApproval.wizard.approvers.status'} />}
                            options={statuses?.map(status => ({
                                key: status.type,
                                text: status.translationKey ? intl.formatMessage({ id: status.translationKey }) : '',
                                data: status
                            }))}
                            type={EDropDownType.DEFAULT}
                        />
                    </Row>
                </GreyBox>
            )}
            <Button type="button"
                typeSchema={EButtonTypeSchema.TEXT}
                disabled={(eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.approvers || [])?.map(elem => (!!elem?.approver?.firstName && !!elem.status)).includes(false)}
                onClick={onAddItem}
                leftIco={faPlus}
            >
                <FormattedMessage id="eApproval.button.add-approver" />
            </Button>
        </Wrapper>
    );
};