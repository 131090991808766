import React, { FC, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { EApprovalContext, EApprovalTypes, IEApprovalContext, IMainStepProps } from '..';
import { Row } from '../../../../Common/Row/Row';
import { TextComponent } from '../../../../Common/Inputs/TextComponent';
import { NewRT } from '../../../../Common/RichText/NewRT';

const Wrapper = styled.div`
    width: 100%;
    > div {
        margin-bottom: 1rem;
    }
    > :last-child {
        margin: 0;
    }
`;

export const ExpensesStep3: FC<IMainStepProps> = props => {
    const intl = useIntl();
    const eApprovalContext = useContext<IEApprovalContext>(EApprovalContext);

    const onTextChange = (value: string) => {
        eApprovalContext.modifyData(value, 'placeOfEvent', props.context, props.stepName, props.stepNumber);
    };

    useEffect(() => {
        eApprovalContext.modifyData(undefined, undefined, props.context, props.stepName, props.stepNumber);
    }, []);

    const onNumberChange = (value: string) => {
        const totalAmountData = eApprovalContext.data?.[EApprovalTypes.Expenses].eventDescription.totalAmount;
        if (/^\d+\.?\d{0,6}$/.test(value) || value === '') {
            // tslint:disable-next-line:no-null-keyword
            eApprovalContext.modifyData({ number: value, unit: null }, 'numberOfParticipants', props.context, props.stepName, props.stepNumber);
            eApprovalContext.modifyData({ number: String(Number(totalAmountData.number) / Number(value)), unit: totalAmountData.unit }, 'amountPerParticipant', props.context, props.stepName, props.stepNumber);
        }
    };

    const onContractDescriptionChange = (value: string) => {
        if (value !== eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.information) {
            const span = document.createElement('span');
            span.innerHTML = value;
            if (span.innerText?.trim()?.length > 0) {
                eApprovalContext.modifyData(value, 'information', props.context, props.stepName, props.stepNumber);
            } else if (span.innerText?.trim()?.length === 0) {
                eApprovalContext.modifyData('', 'information', props.context, props.stepName, props.stepNumber);
            }
        }
    };

    return (
        <Wrapper>
            <Row numberOfElements={1} noBorder>
                <div>
                    <NewRT
                        label={intl.formatMessage({ id: `eApproval.wizard.step3.${[EApprovalTypes.Expenses]}.participantsInfo` })}
                        required
                        content={eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.information}
                        onChange={onContractDescriptionChange}
                    />
                </div>
            </Row>
            <Row numberOfElements={2} noBorder>
                <TextComponent
                    label={intl.formatMessage({ id: `eApproval.wizard.step3.${[EApprovalTypes.Expenses]}.numberOfParticipants` })}
                    value={eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.numberOfParticipants?.number}
                    onChange={onNumberChange}
                    required
                />
                <TextComponent
                    value={eApprovalContext.data?.[EApprovalTypes.Expenses]?.[props.stepName]?.placeOfEvent}
                    label={intl.formatMessage({ id: `eApproval.wizard.step3.${[EApprovalTypes.Expenses]}.placeOfEvent` })}
                    onChange={onTextChange}
                />
            </Row>
        </Wrapper>
    );
};
